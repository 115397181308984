import React from "react";
import Header from "../../../components/sections/Header";
import HeadData from "../../../data/HeadData";

import JotformEmbed from "react-jotform-embed";
import TitleDiv from "../../../components/controls/TitleDiv";
import Layout from "../../../components/layout/Layout";

const path = ["Employment Application Form"];

const EmploymentApplicationPage = () => {
  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData title="NWP Employment Application" />
        <Header />
        <TitleDiv
          parentText="Jobs"
          title="Employment Application Form"
          path={path}
        />
        <div className="w-full">
          <JotformEmbed src="https://form.jotform.com/221628698583471" />
        </div>
      </div>
    </Layout>
  );
};

export default EmploymentApplicationPage;
